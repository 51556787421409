import Vue from 'vue'
import Router from 'vue-router'
import JobWidgetComponent from './components/JobWidget/JobWidget-component.vue'
Vue.use(Router)

const router = new Router({
    routes: [
        {
          path: '/',
          name: 'JobWidget',
          component:JobWidgetComponent,
          meta: { auth: false }
        },
    ]
})

export default router
