<template>
  <div>
    <div>
      <div class="recent-job-openings full-preview-container">
        <h3 :style="{'color':DynamicStyles.Titlecolor,'font-family':DynamicStyles.font}">Recent Job Openings</h3>
          <div class="recent-j  ob-openings-list"> 
           <div v-if="DynamicStyles">
            <div :style="{'background':DynamicStyles.colors.backGround,'border':DynamicStyles.borders,'font-family':DynamicStyles.font}"  v-for="data in lists" :key="data._id"  class="findjob-list-card">
              <div  class="findjob-list-row" >
                <a href="javascript:Void(0)" class="routing-class"></a>
                <div :style="{'font-family':DynamicStyles.font}" class="find-jpb-date">{{ data.momentdate }}</div>
                <div class="findjob-list__rht">
                  <div  class="findjob-list__rht__title flexx">
                    <h2 :style="{'color':DynamicStyles.colors.titleText,'font-family':DynamicStyles.font}">
                     {{ data.jobTitle}}
                     </h2>
                  </div>
                  <div class="findjob-list__rht__list flexx">
                      <div :style="{'color':DynamicStyles.colors.subText,'font-family':DynamicStyles.font}">
                        <img  src="../../assets/img/location.png" alt="location">
                        {{ data.jobLocation?.[0] || 'Default Location' }}
                      </div>
                      <div :style="{'color':DynamicStyles.colors.subText,'font-family':DynamicStyles.font}">
                       <img src="../../assets/img/onsite.png" alt="location">
                        {{ data.modeOfWork }}
                      </div> 
                      <div :style="{'color':DynamicStyles.colors.subText,'font-family':DynamicStyles.font}">
                        <img src="../../assets/img/vector_icon.png" alt="location">
                        {{ data.typeOfWork }} 
                      </div>
                 </div>
              </div> 
            </div>
              <div :style="{'background':DynamicStyles.colors.backGround,'border':DynamicStyles.borders,'font-family':DynamicStyles.font}"  class="findjob-list-card-btm" >
                <span :style="{'color':DynamicStyles.colors.subText,'font-family':DynamicStyles.font,'border':DynamicStyles.as}" class="findjob-list-exp">{{data.experienceMinimum}}-{{ data.experienceMaximum}} Years Exp. </span>
                <span :style="{'font-family':DynamicStyles.font}" class="opening-text">{{ data.positionAvailable }} Openings</span>
              </div>
           </div>  
          </div>  
           <div class="view-all flexx">
             <button class="button-reg btn-md">View All Jobs</button>
           </div>
         </div>
       </div>
     </div>
    </div>
 </template>
<script>
import axios from './../../config/axios.cofig';
import moment from 'moment';

export default {
  props: {
    clientId: {
      type: String,
    },
    widgetStyle: {
      type: Object,
      default: () => ({})
    }
  },
  mounted() {
    this.getList();
    console.log('Client ID:', this.clientId);
  },
  data() {
    return {
      lists: [],
      momentdate: '',
      DynamicStyles: {},
 
      };
      },
  methods: {
    async getList() {
  try {
    const param = {
      limit: 3,
      offset: 1
    };
    const response = await axios.get('careersite/job/customer-job-list?customerId=' + this.clientId + '&limit=' + param.limit + '&offset=' + param.offset);
    this.lists = response.data.data.list;
    this.DynamicStyles= response.data.data.settings;
    // Assign DynamicStyles here once
    this.DynamicStyles.borders= `1px solid ${response.data.data.settings.colors.border}`,
    this.lists.forEach(data => {
      this.momentdate = moment(data.createdAt).fromNow();
      data.momentdate = this.momentdate;
    });
  } catch (error) {
    console.log(error);
  }
},
}
}



</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter", sans-serif;
}

.full-preview-container {
  max-width: 792px;
  margin: auto;
  border: 0;
  padding: 7.5rem 0 0;
}


.flexx {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.recent-job-openings h3 {
  text-align: center;
  margin-top: 0;
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  margin-bottom: 3.25rem;
}

.findjob-list-card {
  border-radius: 0.3875rem;
  border: 1px solid #c3c3c3;
  background-color: #fff;
  margin-bottom: 1rem;
}

.findjob-list-card .findjob-list-row {
  position: relative;
  z-index: 0;
  padding: 1.25rem;
}

.findjob-list-card .findjob-list-row a.routing-class {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.findjob-list-card .findjob-list-row .find-jpb-date {
  position: absolute;
  top: 1rem;
  right: 0.75rem;
  font-size: 0.68725rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0em;
  color: #373737;
}

.findjob-list__rht__title h2 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #122039;
  text-transform: capitalize;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.findjob-list-card .findjob-list-row .findjob-list__rht__list {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem 1.25rem;
  margin-bottom: 0.15rem;
  position: relative;
  z-index: 9;
}

.findjob-list-card .findjob-list-row .findjob-list__rht__title h2 {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #122039;
  text-transform: capitalize;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.findjob-list-card .findjob-list-card-btm {
  border-radius: 0 0 6px 6px;
  border-top: 1px solid #c3c3c3;
  background: #fff;
  padding: 0.75rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.findjob-list-card .findjob-list-card-btm .findjob-list-exp {
  border-radius: 4px;
  border: 1px solid #c3c3c3;
  background: #fff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #373737;
}

.findjob-list-card .findjob-list-card-btm span.opening-text {
  color: #00b178;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  text-transform: none;
}
.recent-job-openings .view-all {
  justify-content: center;
  align-items: center;
  margin-top: 2.25rem;
}
.recent-job-openings .view-all button {
  background-color: #00b178;
  border: 0;
  color: #fff;
  height: 2.25rem;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem;
  font-weight: 400;
  border-radius: 4px;
  gap: 0.5rem;
}
.findjob-list-card .findjob-list-row .findjob-list__rht__list p {
  background-position: left center;
  background-repeat: no-repeat;
  padding-left: 1.375rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #373737;
  margin: 0;
}
</style>