<!-- JobWidget.vue -->
<template>
  <div :style="widgetStyle">
    <job-widget-component :clientId="clientId" :widgetStyle="{ color: 'blue' }" ></job-widget-component>
  </div>
</template>
<script>
import JobWidgetComponent from './components/JobWidget/JobWidget-component.vue';

export default {
  components: { JobWidgetComponent },
  name: 'JobWidget',  
  props: {
    clientId: {
      type: String,
      required: true
    },
    widgetStyle: {
      type: Object,
      default: () => ({})
    }
    },
  mounted() {
    console.log('From Prop', this.widgetStyle);
  }
}

</script>

<style scoped>
/* Scoped styles for the job widget */
</style>
