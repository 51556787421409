// main.js
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue} from 'bootstrap-vue'
import AtUI from 'at-ui'
// import 'at-ui-style/css/at.css'
import 'document-register-element/build/document-register-element'
import vueCustomElement from 'vue-custom-element'

Vue.use(vueCustomElement)
Vue.use(BootstrapVue)
Vue.use(AtUI)
Vue.config.productionTip = false

// Define router
App.router = router

// Register custom element
Vue.customElement('job-widget', App)
